import React, { useContext } from "react"
import Template from "../components/template"
import ContactForm from "../components/contact-form"
import "../styles/team.css"
import { teamContent } from "../content/team"
import { SettingsContext } from "../contexts/settings"

const TeamPage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = teamContent[settings.language]
    return (
        <Template currentPage="team">
            <section>
                <div className="end-of-site">
                    <h1>{content.headline}</h1>
                    <h2 className="headline-team">{content.headlineSecond}</h2>
                    <div className="team-container">
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Caro_Zieringer.jpg" alt="Caro Zieringer"/>
                            <p className="team-name">Caro Zieringer</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task1}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>
                                        {content.text1_1}   
                                    </p>
                                    <p>
                                        {content.text1_2}
                                        <a className="link" href="https://twitter.com/ZieCaro" target="_blank" rel="noopener noreferrer">
                                            {content.text1_Link1}
                                        </a>
                                        {content.text1_3}
                                        <a className="link" href="https://www.linkedin.com/in/carolin-zieringer/" target="_blank" rel="noopener noreferrer">
                                            {content.text1_Link2}
                                        </a>
                                        .
                                    </p>
                                </details>
                                <a className="link-bold" href="mailto:caro@nocturnal-unrest.de">{content.contact}</a>
                            </div>    
                        </div>
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Helene_Roehnsch.jpg" alt="Helene Röhnsch"/>
                            <p className="team-name">Helene Röhnsch</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task10}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>{content.text10}</p>
                                </details>
                                <a className="link-bold" href="mailto:helene@nocturnal-unrest.de">{content.contact}</a>
                            </div>
                        </div>
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Judith_Hesselmann.jpg" alt="Judith Hesselmann"/>
                            <p className="team-name">Judith Hesselmann</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task3}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>{content.text3}</p>
                                </details>
                                <a className="link-bold" href="mailto:judith@nocturnal-unrest.de">{content.contact}</a>
                            </div>
                        </div>
                    </div>
                    <div className="team-container">
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Lisa_Wagner.jpg" alt="Lisa Wagner"/>
                            <p className="team-name">Lisa Wagner</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task4}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>{content.text4}</p>
                                </details>
                                <a className="link-bold" href="mailto:lisa@nocturnal-unrest.de">{content.contact}</a>
                            </div>
                        </div> 
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Magdalene_Hengst.jpg" alt="Magdalene Hengst"/>
                            <p className="team-name">Magdalene Hengst</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task2}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>{content.text2}</p>
                                </details>
                                <a className="link-bold" href="mailto:magdalene@nocturnal-unrest.de">{content.contact}</a>
                            </div>
                        </div>
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Nina_Rosstalnyi.jpg" alt="Nina Rosstalnyj"/>
                            <p className="team-name">Nina Rosstalnyj</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task6}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>{content.text6}</p>
                                </details>
                                <a className="link-bold" href="mailto:nina@nocturnal-unrest.de">{content.contact}</a>
                            </div>
                        </div>
                    </div>
                    <div className="team-container">
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Rahel_Crawford_Barra.jpg" alt="Rahel Crawford Barra"/>
                            <p className="team-name">Rahel Crawford Barra</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task7}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>{content.text7}</p>
                                </details>
                                <a className="link-bold" href="mailto:rahel@nocturnal-unrest.de">{content.contact}</a>
                            </div>
                        </div>
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Sonja_Diederich.jpg" alt="Sonja Diederich"/>
                            <p className="team-name">Sonja Diederich</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task19}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>{content.text19}</p>
                                </details>
                                <a className="link-bold" href="mailto:sonja@nocturnal-unrest.de">{content.contact}</a>
                            </div>
                        </div> 
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Sophi_Lewis.jpg" alt="Sophi Lewis"/>
                            <p className="team-name">Sophi Lewis</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task8}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>                            
                                <p>{content.text8First}</p>
                                <p>
                                    {content.text8Second}
                                    <a className="link" href="https://www.instagram.com/sophi__lewis" target="_blank" rel="noopener noreferrer">{content.text8Link}</a>
                                </p>
                                </details>
                                <a className="link-bold" href="mailto:sophi@nocturnal-unrest.de">{content.contact}</a>
                            </div>
                        </div>
                    </div>
                    <div className="team-container team-start-left">
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Teresa_Bernauer.jpeg" alt="Teresa Bernauer"/>
                            <p className="team-name">Teresa Bernauer</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task12}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>{content.text12}</p>
                                </details>
                                <a className="link-bold" href="mailto:teresa@nocturnal-unrest.de">{content.contact}</a>
                            </div>
                        </div>
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Vero_Schweighoferova.jpg" alt="Vero Schweighoferová"/>
                            <p className="team-name">Vero Schweighoferová</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task9}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>{content.text9}</p>
                                </details>
                                <a className="link-bold" href="mailto:vero@nocturnal-unrest.de">{content.contact}</a>
                            </div>
                        </div>
                    </div>
                    <h2 className="headline-team">{content.headlineThird}</h2>
                    <div className="team-container team-start-left">
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Rebecca_Gotthilf.jpg" alt="Rebecca Gotthilf"/>
                            <p className="team-name">Rebecca Gotthilf</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task5}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>{content.text5}</p>
                                </details>
                            </div>
                        </div>
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Zandile_Darko.jpg" alt="Zandile Darko"/>
                            <p className="team-name">Zandile Darko</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task13}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>
                                        {content.text13First}
                                        <a className="link" href="https://www.zandiledarko.com/" target="_blank" rel="noopener noreferrer">{content.text13LinkFirst}</a>
                                        {content.text13Second}
                                        <a className="link" href="https://www.instagram.com/zandile_darko/" target="_blank" rel="noopener noreferrer">{content.text13LinkSecond}</a>
                                        {content.text13Third}
                                    </p>
                                    <p>{content.text13Fourth}</p>
                                </details>
                            </div>
                        </div>
                    </div>
                    <h2  className="headline-team">{content.headlineFourth}</h2>
                    <div className="team-container team-start-left" id="carmen_salinas">
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Carmen_Salinas.png" alt="Carmen Salinas"/>
                            <p className="team-name">Carmen Salinas</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task11}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>
                                        {content.text11First}
                                        <a className="link" href="https://carmensalinas.de" target="_blank" rel="noopener noreferrer">{content.text11Link}</a>
                                        {content.text11Second}
                                    </p>
                                </details>
                                <a className="link-bold" href="mailto:production@nocturnal-unrest.de">{content.contact}</a>
                            </div>
                        </div>
                        <div className="team-third" id="lisa_gehing">
                            <img className="team-pic" src="/images/team/Lisa_Gehring.jpeg" alt="Lisa Gehring"/>
                            <p className="team-name">Lisa Gehring</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task14}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>
                                        {content.text14First}
                                        <a className="link" href="https://lisagehring.de" target="_blank" rel="noopener noreferrer">{content.text14link}</a>
                                        {content.text14Second}
                                    </p>
                                </details>
                                <a className="link-bold" href="mailto:production@nocturnal-unrest.de">{content.contact}</a>
                            </div>
                        </div>
                    </div>
                    <h2 className="headline-team">{content.headlineFifth}</h2>
                    <div className="team-container">
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Anna_Zhu.png" alt="Anna Zhu"/>
                            <p className="team-name">Anna Zhu</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task16}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>{content.text16}</p>
                                </details>
                            </div>
                        </div>                 
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Inga_Baumgartner.jpg" alt="Inga Baumgartner"/>
                            <p className="team-name">Inga Baumgartner</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task15}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>{content.text15}</p>
                                </details>
                            </div>  
                        </div>
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Susi_Sauer.jpg" alt="Susi Sauer"/>
                            <p className="team-name">Susi Sauer</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task17}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>{content.text17First}</p>
                                    <p>
                                        {content.text17Second}
                                        <a className="link" href="https://www.loved.de/de" target="_blank" rel="noopener noreferrer">{content.text17Link}</a>
                                        {content.text17Third}
                                    </p>
                                </details>
                            </div>
                        </div>
                    </div>
                    <div className="team-container team-start-left">
                        <div className="team-third">
                            <img className="team-pic" src="/images/team/Connor_Lanigan.jpg" alt="Connor Lanigan"/>
                            <p className="team-name">Connor Lanigan</p>
                            <div className="lower-text-team">
                                <p className="mint-text">{content.task18}</p>
                                <details>
                                    <summary className="ausklapptext"><span className="underline">{content.moreInformation}</span></summary>
                                    <p>{content.text18}</p>
                                </details>
                            </div>
                        </div>
                    </div>
                    <h2 className="headline-team">{content.headlineSixth}</h2>
                    <p>Adrian César Montufar</p>
                    <p>Andreas Streinzer</p>
                    <p>Bárbara Luci Carvalho</p>
                    <p>Bianca Bertrams</p>
                    <p>Brix Lange</p>
                    <p>Carlotta Teyke-Saathoff</p>
                    <p>Caroline Frölich</p>
                    <p>Chloe Turner</p>
                    <p>Ella McGrane</p>
                    <p>Eva von Redecker</p>
                    <p>Franz-Josef Hanke (Arbeitskreis Barrierefreies Internet)</p>
                    <p>Jeann Vogt und das Team von NODE</p>
                    <p>Lucy Duggan</p>
                    <p>Matthias Nikutta</p>
                    <p>Melmun Bajarchuu</p>
                    <p>Nora Schratz</p>
                    <p>Patricija D. Popovaite</p>
                    <p>Sebastian Köthe</p>
                    <p>Tabea Dibah Jian</p>
                    <p>Vanessa Eileen Thompson</p>
                    <p>Viktoria Reisch</p>
                    <p>Zwoisy Mears-Clarke</p>
                    <h2>{content.secondHeadlineSeventh}</h2>
                    <p>
                        {content.contactForm}
                    </p>
                    <ContactForm apiSuffix="team" mailto="mailto:info@nocturnal-unrest.de" email="info@nocturnal-unrest.de" titel={content.titelContactForm}></ContactForm>
                </div>
            </section>
        </Template>
    )
}

export default TeamPage