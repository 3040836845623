const teamContent = {
    german: {
        headline: "TEAM",
        headlineSecond: "nOu-Kollektiv: Konzept, Organisation und Kuration",   
        task1: "Projektkoordination, PR, Budgetierung, Antragswesen",
        text1_1: "Caro ist politische Theoretikerin und Kulturanthropologin mit einer Schwäche für Sport, Kaffee und ihren Hund. In ihrer Arbeit beschäftigt sie sich vor allem mit feministischen und postkolonialen Perspektiven auf Körper sowie radikalen Demokratietheorien. Darüber hinaus hegt sie eine eigenwillige Leidenschaft für Excel und Zeitmanagement.",
        text1_2: "Hier findet ihr Caro auf Twitter ",
        text1_Link1: "@ZieCaro",
        text1_3: " und ",
        text1_Link2: "LinkedIn",
        contact: "Kontakt",
        task2: "Awareness, Autonome Tutorien, Kommunikation mit Hafen 2",
        text2: "Maggi studiert in den letzten Zügen Politische Theorie im Master in Frankfurt a. M./ Darmstadt und interessiert sich vor allem für feministische Theorie, moralphilosophische Fragen und Überlegungen darüber, wer wo und wann Zugang zu öffentlichen Räumen hat. Sie teilt gerne Ohr, Herz und Zahnbürste, aber bei der letzten Pommes hört der Spaß auf.",
        task3: "Barrierefreiheit, Website",
        text3: "Judith hat nach einem B.A. in Politikwissenschaften/Philosophie gemerkt, dass sie gern in der und/oder für die Kunst arbeiten will. Sie schätzt sich deswegen glücklich, nun internationale Dramaturgie in Frankfurt/M. zu studieren. Wenn nicht gerade Pandemie ist, trifft Judith gerne Menschen im RL, z. B. im Theater oder am Kanal. Judith interessiert sich für Öko-Themen, wie Ökologie und Ökonomie und trägt gern Kleidung mit geometrischen Formen.",
        task4: "Barrierefreiheit, Website, PR",
        text4: "Lisa ist Soziologin und arbeitet in ihrem Dayjob als Bildungsreferentin. Als Verfechterin der Revenche Bed Time Procrastination, wird sie gerne nachts für das Festival kreativ und beschäftigt sich dann mit atmosphärischen Lernen und Erfahren im öffentlichen Raum. Die restliche Zeit nervt sie alle mit ihren Katzen Klaus und Lou - sogar ihre Katzen selbst.",
        headlineThird: "Teil des Kurations-Teams waren außerdem...",
        task5: "Kurations-Mitglied",
        text5: "Rebecca studiert einen Master zum Thema Intersektionalität, was eigentlich schon ein Widerspruch an sich ist. Als ob das nicht genug wäre, versucht sie sich regelmäßig aus dem Wirrwarr an roten Fäden im Kopf mit großer Anstrengung zu befreien, um dem Spagat zwischen akademischem Update und politischen Projekten irgendwie zu mastern. Zwischen quer und queer, pöbelig und präzise, weit ausholend und plakativ, bewegen sich ihre Auseinandersetzungen mit Themen um Rassismus, (Queer-)Feminismus und Intersektionalität sowohl in der Uni als auch in Tanz, Performance und Realität. Außerdem isst sie äußerst gern Erdnussbutter.",
        task6: "PR",
        text6: "Nina wollte immer Schauspielerin werden, hat in Frankfurt/M. dann aber ihre Leidenschaft für Politikwissenschaften entdeckt und studiert jetzt mit Herz und Seele in Wien „Internationale Beziehungen“. Unresting nights verbringt sie am liebsten unterm Sternenhimmel am Meer oder durchtanzt mit Freund:innen.",
        task7: "Awareness, Technikkoordination",
        text7: "Rahel arbeitet seit ihrem Studium der Angewandten Theaterwissenschaften als freischaffende Künstlerin und beendet gerade einen Master der Soziologie. Sie interessiert sich für Gemeinschaftlichkeiten, Kampfsport, Kritik, Heilungs- und Sorgeprozesse. Ihre Pflanzen sterben trotzdem ständig.",
        task8: "Website, Awareness",
        text8First: "Sophi hat Philosophie und Soziologie studiert. Grade arbeitet sie an einem freien Doku-Filmprojekt und ist PhD-Studentin. Häufig packt sie ihr Kolumbien-Fernweh, ihre Liebe zur Musik (Song&WordWriting) und ihre Leidenschaft für viel Bewegung im Freien. Wenn nicht Lockdown ist, durchtanzt sie mit Freund:innen Kunst- und Kulturräume.",
        text8Second: "Hier findet ihr Sophi auf ",
        text8Link: "Instagram.",
        task9: "PR",
        text9: "Vero hat bereits nach dem Sinn des Lebens in Kommunikationswissenschaft, Kulturanthropologie, Humanmedizin und in einem „Etwas mit Diversität“-Studium gesucht; demnächst möchte sie das noch mit Hebammenkunde probieren. Mit einem inklusiven, queerfeministischen Arbeitsansatz, bitte. Nebenbei versucht sich Vero in Bildungsarbeit und diversen (links-)politischen Projekten. Vero ist radikal in ihrer Art und Weise Popcorn zuzubereiten (immer salzig), vermisst die Liegewägen bei den Nachtzügen der Deutschen Bahn und baut leidenschaftlich gerne richtig hohe Türme mit ihrem Bezugskind.",
        headlineFourth: "Produktionsleitungs-Team",
        headlineFifth: "Website und Grafik",
        headlineSixth: "Danke für die Unterstützung",
        task10: "Awareness, Antragswesen",
        text10: "Helene studiert Dramaturgie und Politische Theorie, braucht als allererstes einen Kaffee, sonst geht da nichts. Wenn dann was geht, arbeitet sie als Redakteurin bei der Live-Sendung „Kulturzeit“. Außerdem interessiert sie sich sehr für feministische Theorie, Theater und generell für  Möglichkeitsräume des Performativen. Natürlich noch für einiges mehr, aber das ist hier nicht so relevant. Beim Festival wirft sie unter anderem ein Auge auf dramaturgische Prozesse und plant mit am Awareness-Konzept.",
        task11: "Verträge, Buchhaltung, Kommunikation mit Förder:innen",
        text11First: "Carmen ist freie Dramaturgin und Produzentin und arbeitet mit darstellende Künstler:innen und Kulturinstitutionen in Deutschland und Spanien. Sie studierte Kunstgeschichte und Dramaturgie und nahm an der Akademie für Performing Arts Producer vom Bündnis internationaler Produktionshäuser e.V. teil. Hier geht’s zur ",
        text11Link: "Website",
        text11Second: " von Carmen.",
        task12: "Technikkoordination, Kommunikation mit Spielstätten",
        text12: "Teresa hat einen Master-Abschluss in Theater-, Film- und Medienwissenschaften und koordiniert jetzt das Outreach-Projekt „Places to See“ am Museum für Moderne Kunst (MMK) in Frankfurt/M. In den letzen fünf Jahren lag ihr Fokus auf Projekten, die sich kreativ und kritisch mit dem Stadtraum und der Rolle kultureller Institutionen auseinandersetzen. Oft findet man sie in der Wohnung und/oder einfach auf der Straße tanzen. Besonders gern betreut sie die dramaturgischen Prozesse im Festival.",
        task13: "Kurations-Mitglied",
        text13First: "Zandile ist Performerin und Theatermacherin. Sie studierte u.a. am Rose Bruford College in London und ist Teil des afrodeutschen Künstlerkollektivs Label Noir. Sie war Ensemble-Mitglied bei Hajusom und spielte im Rahmen diverser Produktionen u.a. auf Kampnagel HH, in den Münchner Kammerspielen oder im Maxim Gorki Theater. Hier gehts zu ihrer ",
        text13LinkFirst: "Website",
        text13Second: " und unter ",
        text13LinkSecond: "@zandile_darko",
        text13Third: " findet ihr sie auf Instagram.",
        text13Fourth: "(Foto: Carsten Zoltan)",
        task14: "Verträge, Buchhaltung, Kommunikation mit Förderer:innen",
        text14First: "Lisa ist freie Produktionsleitung und arbeitet mit darstellenden Künstler:innen in Berlin und Frankfurt/M. Sie nahm an der Akademie für Performing Arts Producer vom Bündnis internationaler Produktionshäuser teil. Durch Unterstützung in Administration, Organisation und Kommunikation bei Neuproduktionen und Gastspielen, schafft sie Raum und Zeit für künstlerische Arbeit. Hier geht’s zur ",
        text14link: "Website",
        text14Second: " von Lisa.",
        task15: "Website",
        text15: "Inga ist Informatik Studentin in Frankfurt und da nur durch Zufall reingerutscht, aber jetzt läuft es irgendwie. Ihr liebstes Hobby ist Schlafen, aber Roller Derby macht ihr auch manchmal Spaß.",
        task16: "Illustration, Webdesign",
        text16: "Nach ihrem Bachelor in Animation & Game and Economics, arbeitete Anna für internationale TV- und Filmproduktionen wie \"Game of Thrones\" und \"Disney's 101 Dalmatian Street\". Seitdem ist sie auf der Suche nach weiteren kreativen Herausforderungen als freiberufliche Illustratorin, sowie Animations- und New-Media-Künstlerin. Annas Interessen reichen von Soziologie, Psychologie und Philosophie über Politik und Blockchain-Technologie.",
        task17: "Corporate Identity",
        text17First: "Susi ist Grafik-Designerin und Art Direktorin. Aktuell arbeitet sie bei loved in Hamburg. Für Nocturnal Unrest hat sie die Corporate Identity entwickelt.",
        text17Second: "Hier erfahrt ihr mehr über die Arbeit von ",
        text17Link: "loved",
        text17Third: ".",
        task18: "Beratung, Unterstützung",
        text18: "Connor hat Informatik studiert und arbeitet jetzt als Software-Entwickler. Am liebsten setzt er seine Fähigkeiten für Projekte wie Nocturnal Unrest ein, da er Spaß an der Zusammenarbeit mit kreativen Menschen hat. Er liebt Zitronenkuchen und hat eine Schwäche für platte Wortwitze, die aber nur seine Schwester lustig findet.",
        secondHeadlineSeventh: "Kontaktformular",
        contactForm: "Deine Frage wurde nicht beantwortet oder du benötigst technischen Support? Kontaktiere uns direkt.",
        moreInformation: "Mehr Informationen",
        titelContactForm: "Kontakt zum nOu-Kollektiv",
        task19: "PR",
        text19: "Sonja hat Musikwissenschaften und Romanistik in Frankfurt/Main studiert und ist neben ihrem Dayjob ehrenamtlich im Bereich Kultur- und Musikvermittlung tätig. Sie beschäftigt sich in ihrer freien Zeit gerne mit feministischer Theorie und der Verbindung von feministischen und politischen Bewegungen. Am liebsten arbeitet sie interdisziplinär und begibt sich auf die Suche nach neuen Darstellungsformen und Klängen. In Zeiten ohne Pandemie ist sie oft nachts singend auf ihrem Fahrrad auf dem Weg von A nach B in Frankfurt zu bewundern. ",

    },
    english:  {
        headline: "TEAM",
        headlineSecond: "nOu collective: Concept, Organization and Curation", 
        task1: "Project coordination, PR, budgeting, proposal writing",
        text1_1: "Caro is a political theorist and cultural anthropologist with a soft spot for sports, coffee and her dog. Her work focuses on feminist and postcolonial perspectives on the body as well as radical theories of democracy. She also has an idiosyncratic passion for Excel and time management.",
        text1_2: "You can find Caro on Twitter ",
        text1_Link1: "@ZieCaro",
        text1_3: " and ",
        text1_Link2: "LinkedIn",
        contact: "Contact",
        task2: "Awareness, Autonomous Tutorials, Communication with Hafen 2",
        text2: "Maggi has nearly finished her Master's programme Political Theory in Frankfurt/M. And Darmstadt. She is mainly interested in feminist theory, moral philosophical questions and reflections on who holds where and when access to public spaces. She likes to share her ear, heart and toothbrush, but she cannot take a joke about the last fry.",
        task3: "Accessibility, Website",
        text3: "After completing a B.A. in Political Science and Philosophy, Judith realized that she would like to work in and/or for the arts scene. She therefore considers herself lucky to now be studying international dramaturgy in Frankfurt/M. When there is no global pandemic, Judith likes to meet people in RL, e.g. in a theatre or at a canal. Judith is interested in eco-topics, such as ecology and economy, and she likes to wear clothes with geometric shapes.",
        task4: "Accessibility, Website, PR",
        text4: "Lisa is a sociologist and works in her day job as an educational consultant. Being an advocate of Revenche Bed Time Procrastination, Lisa likes to get creative for the festival at night. This is when she engages with atmospheric learning and experience in public spaces. The rest of the time she annoys everyone with her cats Klaus and Lou - even her cats themselves.",
        headlineThird: "Also part of the curational team were",
        task5: "Curator",
        text5: "Rebecca is currently enrolled in a Master’s programme on the subject of intersectionality, which is actually a contradiction in itself. As if that were not enough, she regularly tries hard to free herself from the tangle of many red threads in her head in order to somehow master the balancing act between academic update and political projects. Between transversal and queer, polemic and precise, sweeping and striking, her explorations of themes around racism, (queer) feminism and intersectionality move both in university and in dance, performance and reality. She also loves to eat peanut butter.",
        task6: "PR",
        text6: "Nina always wanted to be an actress but then discovered her passion for political science in Frankfurt/M. Now she studies with heart and soul international relations in Vienna. Furthermore, she loves to spend unresting nights being under the stars by the sea or dancing the night away with friends.",
        task7: "Awareness, Technical Coordination",
        text7: "Rahel has been working as a freelance artist since graduating in Applied Theatre Studies and she is currently finishing a master programme in Sociology. She is interested in communalities, martial arts, critique, healing and caring processes. Her plants still die all the time.",
        task8: "Website, Awareness",
        text8First: "Sophi studied philosophy and sociology. She currently works in a freelance documentary film project but she is also a PhD-student. She is often gripped by her love for Colombia, her love for music (song&wordwriting) and her passion for lots of outdoor exercises. When it is not lockdown, she dances through art and cultural spaces with friends. ",
        text8Second: "Here you can find Sophi on ",
        text8Link: "Instagram.",
        task9: "PR",
        text9: "Vero has already searched for the meaning of life in communication studies, cultural anthropology, human medicine and in studying \"something with diversity\"; soon she wants to give it a try in midwifery. With an inclusive, queer-feminist approach, please. On the side, Vero tries her hand at educational work and various (left-wing) political projects. Vero is radical in her way of preparing popcorn (always salty), misses the couchettes on the night trains of Deutsche Bahn and she is passionate about building really high towers with the child she is caring for.",
        headlineFourth: "Production Team",
        headlineFifth: "Website and Graphics",
        headlineSixth: "Big thank you for the support",
        task10: "Awareness, Proposal Writing",
        text10: "Helene studies dramaturgy and political theory. The first thing she needs is a coffee, otherwise nothing works. When things then start to work out, she works as an editor for the live programme “Kulturzeit“. She is very interested in feminist theory, theatre and generally in possible spaces of the performative. Of course, she's interested in a lot more, but that's not too relevant here. In the festival process, she keeps an eye on dramaturgical processes and plans the awareness concept.",
        task11: "Contracts, bookkeeping, communication with sponsors",
        text11First: "Carmen is a freelance dramaturg and producer who works with performing artists and cultural institutions in Germany and Spain. She studied art history and dramaturgy and she participated in the Academy for Performing Arts Producers of the Bündnis internationaler Produktionshäuser e.V. You can find more information about Carmen’s work on her ",
        text11Link: "website",
        text11Second: ".",
        task12: "Technical Coordination, Communication with venues",
        text12: "Teresa holds a Master's degree in Theatre, Film and Media Studies and now coordinates the outreach-project \"Places to See\" at the Museum of Modern Art (MMK) in Frankfurt/M. In the last five years her focus has been on projects that creatively and critically engage with urban spaces and the role of cultural institutions. Often you can find Teresa dancing in her apartment and/or in the streets. Above that, she especially likes to deal with the dramaturgical processes in the festival.",
        task13: "Curator",
        text13First: "Zandile is a performer and theatre-maker. She studied at Rose Bruford College in London and she is part of the Afro-German artist collective Label Noir. She was a member of the Hajusom ensemble and has performed in various productions at Kampnagel HH, the Münchner Kammerspiele and the Maxim Gorki Theater. Click ",
        text13LinkFirst: " here ",
        text13Second: " for her website and under ",
        text13LinkSecond: "zandile_darko",
        text13Third: " you can find her on Instagram.",
        text13Fourth: "(Photo: Carsten Zoltan)",
        task14: "Contracts, bookkeeping, communication with sponsors",
        text14First: "Lisa is a freelance production manager and works with performing artists in Berlin and Frankfurt/M. She participated in the Academy for Performing Arts Producers of the Alliance of International Production Houses. By supporting new productions and guest productions in administration, organization and communication, she creates space and time for artistic work. You can find more information about Lisa’s work on her ",
        text14link: "website",
        text14Second: ".",
        task15: "Website",
        text15: "Inga is a computer science student in Frankfurt and only slipped into that by accident, but it works somehow. Her favorite hobby is sleeping, but sometimes she also enjoys Roller Derby.",
        task16: "Illustration, Webdesign",
        text16: "After finishing her bachelor degrees in Animation & Game and Economics, Anna worked on international TV and movie productions like “Game of Thrones” and “Disney’s 101 Dalmatian Street”. She has been looking for a more creative outlet as a freelance illustrator as well as an animation and new media artist ever since. As an artist drawn to progressive topics, Anna’s interests span from sociology, psychology and philosophy to politics and blockchain technology.",
        task17: "Corporate Identity",
        text17First: "Susi is a graphic designer and art director. She developed the corporate identity for Nocturnal Unrest. She currently works for loved in Hamburg.",
        text17Second: "Click ",
        text17Link: "here",
        text17Third: " for more information about the work of loved.",
        task18: "Consulting, support",
        text18: "Connor studied computer science and now works as a software developer. He loves to use his skills on projects like Nocturnal Unrest as he enjoys working with creative people. He as well loves lemon cake and has a weakness for flat word jokes, which only his sister finds funny.",
        secondHeadlineSeventh: "Contact Form",
        contactForm: "Your question hasn't been answered or you need technical support? Contact us directly.",
        moreInformation: "More information",
        titelContactForm: "Contact the nOu collective",
        task19: "PR",
        text19: "Sonja studied musicology and Romance studies in Frankfurt/Main. In addition to her day job, Sonja works as a volunteer in the field of cultural and music education. In her free time, she likes to work on feminist theory and the connection between feminist and political movements. She prefers to work in an interdisciplinary way and to search for new forms of presentation and sounds. In times without a pandemic, she can often be found singing at night on her bicycle riding from A to B in Frankfurt. ",
    }
}

export {teamContent}